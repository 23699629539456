jQuery(function ($) {

    if (!$('.page-banner-content').length) { return; }

    let $header = $('header.site-header');
    let $slider = $('div.page-banner .slick-slider');
    let $live_section = $('div.page-banner .slider-text-live');


    function toggle_header_color(white) {
        white = ThemeJS.Media_Queries.is_at_least('lg') ? white : true;
        $header.toggleClass('color-white', white);
    }
    toggle_header_color($('.page-banner-content').first().hasClass('dark-overlay'));
    $(window).on('breakpoint_changed', function () {
        if ($header.hasClass('transparent-at-top')) {
            let white = !ThemeJS.Media_Queries.is_at_least('lg');
            if (!white) {
                let $active_banner_content = ($slider.length) ? $(`.slick-slide[data-slick-index='${$slider.slick('slickCurrentSlide')}'] .page-banner-content`).first() : $('.page-banner-content').first();
                white = $active_banner_content.hasClass('dark-overlay');
            }
            toggle_header_color(white);
        }
    });

    if (!$slider.length) { return; }

    $slider.slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 5000,
        focusOnSelect: true
    });

    $slider.on('beforeChange', function (e, slick, current, next) {
        let $next_slide = $(`.slick-slide[data-slick-index='${next}']`, $slider);
        if (!$next_slide.length) { return; }
        toggle_header_color($('.page-banner-content', $next_slide).hasClass('dark-overlay'));
    });

    $slider.on('afterChange', function (e, slick, current) {
        let aria_live = this.contains(document.activeElement) ? 'polite' : 'off';
        $live_section.attr('aria-live', aria_live);
        $live_section.html(`Active slide title: ${$('.h1', slick.$slides.filter('.slick-active')).html()}`);
    });

});